@use '@angular/material' as mat;

@include mat.core();

body {
  @include mat.checkbox-density(-3);
  @include mat.radio-density(-2);
  @include mat.slide-toggle-density(-3);
}

$mat-lemon: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #26c6da,
  500: #3ecbf4,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  A100: #2cc4f5,
  A200: #2db2df,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$mat-lemon-green: (
  50: #ECFAEC,
  100: #D0F2D0,
  200: #B0E9B1,
  300: #90E092,
  400: #79D97A,
  500: #61D263,
  600: #59CD5B,
  700: #4FC751,
  800: #45C147,
  900: #33B635,
  A100: #FDFFFD,
  A200: #CAFFCB,
  A400: #97FF99,
  A700: #7EFF80,
  contrast: (50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$typography-config: mat.m2-define-typography-config($font-family: 'Muli, sans-serif',
    $headline-5: mat.m2-define-typography-level(40px, 56px, 800, $letter-spacing: normal),
    $headline-6: mat.m2-define-typography-level(24px, 40px, 600, $letter-spacing: normal),
    $subtitle-1: mat.m2-define-typography-level(20px, 32px, 500, $letter-spacing: normal),
    /** This doesn't correspond to <h4> tag as per docs https://github.com/angular/components/issues/26351 */
    $subtitle-2: mat.m2-define-typography-level(12px, 28px, 400, $letter-spacing: normal),
    $body-1: mat.m2-define-typography-level(16px, 24px, 500, $letter-spacing: normal),
    $body-2: mat.m2-define-typography-level(16px, 24px, 500, $letter-spacing: normal),
    $caption: mat.m2-define-typography-level(12px, 20px, 400, $letter-spacing: normal),
  );
@include mat.all-component-typographies($typography-config);

html .mat-typography h4 {
  font-size: 18px;
  font-weight: 400;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$candy-app-primary: mat.m2-define-palette($mat-lemon);
$candy-app-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$candy-app-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$candy-app-theme: mat.m2-define-light-theme((color: (primary: $candy-app-primary,
        accent: $candy-app-accent,
        warning: $candy-app-warn,
      ),
      typography: $typography-config,
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);