/* You can add global styles to this file, and also import other style files */
html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-typography-button-letter-spacing: 'normal';
  --mdc-filled-button-label-text-tracking: 'normal';
  --mdc-text-button-label-text-tracking: 'normal';
  --mdc-filled-text-field-hover-active-indicator-color: #2ecbfe;
  --mat-tab-header-label-text-size: 16px;
  --mat-form-field-container-text-line-height: 20px;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2ecbfe;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #2ecbfe;
}

html .mat-mdc-dialog-container .mdc-dialog__content {
  letter-spacing: normal;
}

html body .kokosivu .backdrop,
html body .kokosivu .spinner-three-bounce {
  position: fixed;
}

html .mdc-text-field:not(.mdc-text-field--outlined) {
  overflow: visible;
  padding: 0px;
}

html .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgb(0, 0, 0);
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset;
}

html .mat-mdc-form-field-focus-overlay {
  display: none;
}

html .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 34px;
}

html .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
  top: 28px;
}

html .mat-mdc-form-field-hint-wrapper,
html .mat-mdc-form-field-error-wrapper {
  padding: 0;
}

form mat-form-field.mat-mdc-form-field {
  width: 100%;
}

html body div mat-form-field .mat-mdc-form-field-infix {
  width: auto;
  min-height: 50px;
  padding-bottom: 0px !important;
}

html .mat-mdc-form-field-icon-prefix>.mat-icon {
  padding: 0;
  margin-top: 15px;
}

html .mat-form-field-appearance-fill .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: translateY(-0px);
}

html .mat-mdc-form-field-icon-suffix {
  height: 30px;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #2ecbfe;
}

html .mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #2ecbfe;
}

html .mat-mdc-form-field {
  width: 100%;
  height: fit-content;
}

html .mdc-text-field--disabled.mdc-text-field--filled {
  background-color: unset;
}

html .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 22px;
}

html .mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.54);
}

html body .mat-mdc-paginator-page-size-label {
  white-space: nowrap;
}

html body .mat-mdc-form-field.mat-mdc-paginator-page-size-select {
  width: 80px;
}

html body .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mdc-notched-outline--no-label .mdc-notched-outline__leading,
html body .mat-mdc-form-field.mat-mdc-paginator-page-size-select .mdc-notched-outline--no-label .mdc-notched-outline__trailing {
  border: unset;
}

html .mat-mdc-radio-button .mdc-radio {
  padding: 5px;
}

html .mat-datepicker-toggle .mat-mdc-button-persistent-ripple {
  display: none;
}

html .mat-datepicker-toggle .mdc-icon-button .mat-datepicker-toggle-default-icon {
  height: 18px;
  margin-left: 10px;
}

.noscroll {
  height: 100%;
  overflow: hidden;
  touch-action: none;
  pointer-events: none;
}

body.mat-typography {
  font: 400 16px/22px Muli, sans-serif;
}

body .mat-mdc-tab {
  font-size: 16px;
}

body .mat-mdc-tab-label,
body .mat-mdc-tab-link {
  font-size: 1rem;
}

body .mat-mdc-tab {
  letter-spacing: normal;
}

/** VALIKKO JA APP-COMPONENT */
.valikko-underlay {
  pointer-events: auto;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 3;
  top: 0;
  left: 0;
}

.valikko {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
  z-index: 4;
  position: fixed;
  width: 220px;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14),
    0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.valikko-sisalto {
  margin-left: 220px;
}

.valikko-sisalto.kapea {
  margin-left: 0;
}

* {
  box-sizing: border-box;
}

.list-page,
.detail-page,
.asetukset-page {
  padding: 0px 75px 30px;
}

html body div.list-loading .backdrop {
  animation: lemon-tummenna-tausta .2s ease-in-out 0s 1 normal both running;
}

@keyframes lemon-tummenna-tausta {
  0% {
    opacity: .0;
  }

  100% {
    opacity: .5;
  }
}

@keyframes lemon-vaalenna-tausta {
  0% {
    opacity: .5;
  }

  100% {
    opacity: .0;
  }
}

/** See PlaceholderWithRequiredIndicatorDirective for more details */
.mat-mdc-form-field-infix:not(:has(label)) .mat-mdc-select-required .mat-mdc-select-placeholder:after {
  content: " *";
}

.asetukset-page .sisalto {
  padding: 30px 20px 20px;
  max-width: 500px;
}

html body div .asetukset-page h3,
html body div .asetukset-page h4 {
  color: rgb(53, 200, 249);
}

html body div .asetukset-page h3 {
  margin: 30px 0 16px;
}

html body div .asetukset-page h3:first-of-type {
  margin: 17.5px 0 16px;
}

.asetukset-page .asetusrypas {
  padding-left: 20px;
}

.asetukset-page .asetusnappulat {
  text-align: center;
  margin-top: 30px;
}

.asetukset-page .laskulogon-vaihto-container {
  margin: auto;
  display: inline-block;
  border: 2px dotted rgb(53, 200, 249);
  border-radius: 30px;
  cursor: pointer;
}

.asetukset-page .laskulogon-vaihto-container .ngx-file-drop__content {
  display: block;
  text-align: center;
  height: auto;
  padding: 30px;
  color: rgb(53, 200, 249);
  cursor: pointer;
}

.ostotositteet-listaus-page .ngx-file-drop__drop-zone--over,
.tositteet-listaus-page .maksutapa-container.ngx-file-drop__drop-zone--over {
  background-color: white !important;
  cursor: pointer;
}

.ostotositteet-listaus-page .maksutapa-container .ngx-file-drop__content,
.tositteet-listaus-page .maksutapa-container .ngx-file-drop__content {
  display: block;
  height: auto;
}

.tositteet-listaus-page .drag-container .ngx-file-drop__drop-zone--over {
  background-color: white;
  cursor: pointer;
}

.tositteet-listaus-page .drag-container .ngx-file-drop__content {
  background-color: white;
  display: flex;
  height: auto;
}

.tositteen-muokkaus-page .drag-box .ngx-file-drop__content {
  cursor: pointer;
  color: gray;
  display: block;
  height: auto;
}

.tositteen-muokkaus-page .drag-box .ngx-file-drop__drop-zone--over {
  background-color: #E8E8E8;
  border-radius: 10px;
  border: 2px transparent;
  border-radius: 10px;
  height: 100%;
}

.tositteen-muokkaus-page .ngx-file-drop__drop-zone--over .drag-text-and-icon .drag-box-text,
.tositteen-muokkaus-page .ngx-file-drop__drop-zone--over .drag-text-and-icon .drag-box-icon {
  pointer-events: none;
}

.asetukset-page .laskulogon-lataus-virhe .laskulogon-vaihto-container {
  border-color: #f44336;
}

.asetukset-page .laskulogon-lataus-virhe .laskulogon-vaihto-container .ngx-file-drop__content {
  color: #f44336;
}

.asetukset-page .laskulogon-vaihto-container .ngx-file-drop__content p {
  margin: 25px 0 0 0;
  cursor: pointer;
}

.asetukset-page .laskulogon-vaihto-container.ngx-file-drop__drop-zone--over {
  background-color: rgba(75, 215, 107, .15);
  cursor: pointer;
}

.list-page .mat-mdc-cell,
.list-page .mat-mdc-header-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-header-center .mat-sort-header-container {
  justify-content: center;
}

.align-header-right .mat-sort-header-container {
  justify-content: flex-end;
}

.list-page .mat-mdc-paginator-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 56px;
  padding: 0;
  width: 100%;
  max-width: 100vw;
}

.list-page .primarybutton {
  font-size: 17px;
  line-height: 43px;
}

.list-page .primarybutton .mat-icon {
  height: 24px;
  width: 24px;
  line-height: 24px;
  font-size: 24px;
}

.list-page .mat-mdc-header-row {
  border: 0;
}

.list-page .mat-mdc-header-row,
.list-page .mat-mdc-row {
  padding: 0;
  border: 0;
}

.list-page .mat-mdc-row {
  min-height: 59px;
}

.list-page .mat-mdc-row,
.list-page .mat-mdc-header-row {
  padding-left: 12px;
  padding-right: 12px;
}

/* .list-page .mat-row:hover { */
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f9f9f9+50,ffffff+100 */
/* background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 50%, rgba(255, 255, 255, 1) 100%); */
/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
/* } */

.list-page .mat-mdc-row,
.list-page .mat-mdc-cell {
  cursor: pointer;
}

.list-page .mat-mdc-header-row,
.list-page .mat-mdc-row:first-of-type {
  margin-top: 0;
}

.list-page .mat-mdc-header-row {
  margin-top: 32px;
  min-height: 32px;
}

.list-page .listaus-haku-ikoni.mat-icon {
  margin-left: 4px;
  margin-right: 4px;
  width: 23px;
  font-size: 23px;
  line-height: 28px;
  vertical-align: bottom;
  color: rgb(44, 181, 226);
}

.vaakatason-scroll-container {
  overflow-x: scroll;
  max-width: 100vw;
  width: 100%;
}


/* DIALOGIT */
.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

html body .mat-mdc-dialog-container .mat-mdc-dialog-surface {
  border-radius: 10px;
}

.lemon-dialog-header {
  text-align: center;
  position: relative;
}

.lemon-dialog-header button {
  position: absolute;
  right: -30px;
  top: 0;
  min-width: 0;
  padding: 0;
}

html body .mdc-dialog .mat-mdc-dialog-content {
  padding: 5px 31px;
  /* Check lemon-dialog-header button right value, minkä pitää olla sama kuin padding-right */
  margin: 0;
}

html body .mat-mdc-dialog-actions {
  padding: 20px;
  justify-content: center;
}

html body .mat-mdc-dialog-actions:last-child {
  margin-bottom: 0;
}

html body lemon-dialog-header h2 {
  margin: 0 !important;
  padding-top: 50px;
  padding-bottom: 30px;
}

div#valikko .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  min-height: inherit;
  max-height: inherit;
  text-align: left;
}

div#valikko .mat-mdc-list-base {
  padding: 0;
}

.mat-mdc-nav-list .mat-mdc-list-item {
  --mdc-list-list-item-label-text-size: .9rem;
}

body input.mat-mdc-chip-input {
  width: 99%;
}

html body [hidden] {
  display: none !important;
}

body .mat-mdc-select-panel {
  max-height: 300px;
}




.flashit {
  animation: flash linear 2s infinite;
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}








html body .kuukausi-valitsin .mat-calendar-period-button {
  pointer-events: none;
}

html body .kuukausi-valitsin .mat-calendar-arrow {
  display: none;
}




html body #tuotteet .mat-mdc-form-field-underline,
html body #tuotteet .mat-mdc-form-field-disabled .mat-mdc-form-field-underline {
  display: none;
}

html body #tuotteet .mat-mdc-form-field-placeholder {
  margin-top: -2px;
  left: 2px;
}

html body #tuotteet .mat-mdc-text-field-wrapper {
  padding-bottom: 0px;
}

html body #tuotteet .mat-mdc-form-field-suffix {
  padding-left: 0;
  padding-right: 3px;
}

html body #tuotteet mat-form-field.ng-invalid.ng-touched .mat-mdc-form-field-flex {
  border: 1px solid #f44336;
  border-radius: 0;
}

html body #tuotteet .mat-mdc-form-field-flex {
  background-color: #ffffff;
  transform: background-color;
  color: #000000;
  border: 1px solid #fff;
  margin: 0;
  border-radius: 0;
  height: 39px;
}

html body #tuotteet .mat-mdc-form-field-infix {
  border: 0;
  padding: 2px;
  margin: 0;
}

html body #tuotteet .mat-mdc-form-field {
  line-height: 25px;
  min-width: unset;
}

html body #tuotteet .mat-mdc-form-field,
html body #tuotteet .mat-mdc-select {
  font-size: 13px;
}

html body #tuotteet .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

html body #tuotteet .mat-mdc-text-field-wrapper .mdc-text-field {
  height: 39px;
}

@media screen and (min-width: 551px) {

  html body #tuotteet .mat-mdc-form-field-flex {
    border-radius: 5px;
  }

  html body #tuotteet .mat-mdc-form-field-placeholder {
    left: 5px;
  }

  html body #tuotteet .mat-mdc-form-field-infix {
    padding: 7px;
  }

}


.laskun-liitetiedostot-droppi .ngx-file-drop__content {
  display: block !important;
  height: 200px !important;
  padding: 20px;
}

.laskun-rivit-liitetiedosto-drop-container.ngx-file-drop__drop-zone--over {
  background-color: unset !important;
}

.laskun-rivit-liitetiedosto-drop-container.ngx-file-drop__drop-zone--over .laskun-rivit-liitetiedosto-drop-laatikko {
  border-style: solid !important;
}

html body .mat-mdc-option {
  font-size: 1rem;
  line-height: 2rem;
}

html body .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap;
}

/** Enables the select panel to have larger with than the associated mat-form-field */
.cdk-overlay-pane:has(.mat-mdc-select-panel) {
  min-width: fit-content;
}

html body .mat-mdc-chip:not(.mat-mdc-basic-chip) .mat-mdc-chip-remove {
  color: #2cc4f5;
  opacity: 0.6;
}

html body .mat-mdc-chip:not(.mat-mdc-basic-chip) {
  background-color: #fff;
  color: #2cc4f5;
  border: 1px solid #2cc4f5;
  padding-top: .7rem;
  padding-bottom: .7rem;
  padding-right: .5rem;
  padding-left: .5rem;
  font-size: 1rem;
  line-height: 1rem;
  min-height: 2.4rem;
  border-radius: 1.2rem;
}

html body .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-size: 1rem;
  color: unset;
}

html body .mat-mdc-chip-set.mat-mdc-chip-grid.mdc-evolution-chip-set:focus .mat-mdc-chip-focus-overlay {
  background: var(--mdc-chip-focus-state-layer-color);
  opacity: var(--mdc-chip-focus-state-layer-opacity);
}

html body mat-chip-grid {
  color: black;
}

html body mat-chip-grid:active {
  color: green;
}

html body mat-chip-grid:focus {
  color: red;
}

html body mat-chip:focus::after {
  color: black;
}

.mat-form-field-appearance-fill .mat-mdc-select-trigger .mat-mdc-select-arrow-wrapper {
  transform: translateY(-4px);
}

.mat-primary .valkoinen.mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: rgb(0, 0, 0);
}

.mat-mdc-button .icon {
  vertical-align: middle;
}

.valkoinen,
.valkoinen .mat-mdc-cell,
.valkoinen .mat-mdc-header-cell,
.valkoinen.mat-mdc-cell,
.valkoinen.mat-mdc-header-cell,
.valkoinen .mat-mdc-list .mat-mdc-list-item,
.valkoinen .mat-mdc-nav-list .mat-mdc-list-item,
.valkoinen .mat-mdc-selection-list .mat-mdc-list-item,
.valkoinen .mat-mdc-select-value-text,
.valkoinen .mat-mdc-select-value,
.valkoinen .mat-mdc-select-placeholder,
.valkoinen .mdc-list-item:hover .mdc-list-item__primary-text,
.valkoinen .mdc-list-item__primary-text,
.valkoinen .mat-mdc-button:not(:disabled) {
  color: rgb(255, 255, 255);
}

.valkoinen .mat-mdc-select-arrow {
  color: rgba(255, 255, 255, 0.54);
}

.valkoinen .mat-mdc-select-disabled .mat-mdc-select-value,
.valkoinen .mat-mdc-select-disabled .mat-mdc-select-value-text {
  color: rgba(0, 0, 0, 0.38) !important;
}

.pyoristettu-potko-select .mat-mdc-select-trigger {
  padding-left: 10px;
  padding-right: 10px;
}

.pyoristettu-potko-select.mat-mdc-form-field .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 0.65);
}

.pyoristettu-potko-select .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline,
.pyoristettu-potko-select .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
  background-color: transparent;
}

.pyoristettu-potko-select.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
  color: rgba(255, 255, 255, 1);
}

.pyoristettu-potko-select.mat-focused .mat-mdc-form-field-underline,
.pyoristettu-potko-select.mat-focused .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
  background-color: rgba(255, 255, 255, 0.4);
}

.pyoristettu-potko-select .mdc-line-ripple {
  display: none;
}

.placeholder-isoilla .mat-form-field-placeholder {
  text-transform: uppercase;
}

/* body .mat-radio-label { padding: 5px; } */

.blue-background {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2ecbfe+0,2ab8e6+100 */
  background: #2ecbfe;
  /* Old browsers */
  background: linear-gradient(to bottom, #2ecbfe 0%, #2ab8e6 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.blue-background .mat-mdc-button.sulje {
  color: white;
}

.lemon-mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
}

html .mat-mdc-button {
  height: auto;
  min-height: 36px;
  white-space: nowrap;
}

html .large-icon-button {
  width: 38px;
  height: 38px;
  padding: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

html .large-icon-button .icon {
  font-size: 38px;
  line-height: 38px;
  width: 38px;
  height: 38px;
}

html .large-icon-button .mat-mdc-button-touch-target {
  width: 38px;
  height: 38px;
}

.dark-gray-text,
button.dark-gray-text,
[mat-button].mat-mdc-button-base.dark-gray-text,
.dark-gray-text .mat-mdc-footer-cell,
.tosite-api-msg {
  color: rgb(124, 124, 124);
}

.gray-text,
button.gray-text,
[mat-button].mat-mdc-button-base.gray-text {
  color: gray;
}

.light-gray-text,
button.light-gray-text,
[mat-button].mat-mdc-button-base.light-gray-text {
  color: lightgray;
}

.light-gray-background {
  background-color: lightgray;
}

.light-blue-background {
  background-color: rgb(62, 203, 244);
}

.list-page .mat-mdc-header-row .mat-mdc-header-cell,
.light-blue-text,
button.light-blue-text,
.mat-mdc-button.mat-mdc-button-base.light-blue-text,
.light-blue-text .mat-mdc-button.mat-mdc-button-base {
  color: rgb(62, 203, 244);
}

.blue-text,
.mat-mdc-cell.blue-text,
button.blue-text,
[mat-button].mat-mdc-button-base.blue-text {
  color: #2a8ff4;
}

.mint-text,
.mat-mdc-cell.mint-text,
button.mint-text,
[mat-button].mat-mdc-button-base.mint-text {
  color: rgb(53, 213, 185);
}

.yellow-text,
.mat-mdc-cell.yellow-text,
button.yellow-text,
[mat-button].mat-mdc-button-base.yellow-text {
  color: rgb(248, 203, 89);
}

.green-text,
.mat-mdc-cell.green-text,
button.green-text,
[mat-button].mat-mdc-button-base.green-text {
  color: #4bd76b;
}

.purple-text,
.mat-mdc-cell.purple-text,
button.purple-text,
[mat-button].mat-mdc-button-base.purple-text {
  color: #f755f5;
}

mat-radio-group.ng-invalid.ng-touched .mdc-radio__outer-circle,
mat-radio-group.ng-invalid.ng-touched .mdc-inner-circle {
  border-color: #f44336;
}

.lemon-error,
.error-text,
.mat-mdc-cell.error-text,
button.error-text,
[mat-button].mat-mdc-button-base.error-text,
mat-radio-group.ng-invalid.ng-touched {
  color: #f44336;
}

html body #tuotteet .lemon-cell .ng-touched.ng-invalid .mat-mdc-text-field-wrapper {
  border-color: #f44336;
  background-color: rgb(255, 243, 241);
}

.red-text,
.mat-mdc-cell.red-text,
button.red-text,
[mat-button].mat-mdc-button-base.red-text {
  color: rgb(255, 72, 102);
}

html body div .primarybutton.mat-mdc-button.mat-unthemed,
html body div .secondarybutton.mat-mdc-button.mat-unthemed {
  letter-spacing: normal;
  font-weight: 500;
  background-color: #2cc4f5;
  color: white;
  font-size: 1rem;
  min-height: 2.4rem;
  border-radius: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

html body div .primarybutton[disabled].mat-mdc-button-base.mat-unthemed,
html body div .secondarybutton[disabled].mat-mdc-button-base.mat-unthemed {
  color: rgba(0, 0, 0, 0.26);
}

html body div .secondarybutton.mat-mdc-button.mat-unthemed {
  background-color: white;
  border: 1px solid #2cc4f5;
}

html body div .secondarybutton.mat-mdc-button.mat-unthemed {
  color: #2cc4f5;
}

html body div .list-page .primarybutton.mat-mdc-button.mat-unthemed {
  padding-left: 8px;
  padding-right: 18px;
}

html body app-root div .selectedmenu,
html body app-root div .mat-mdc-nav-list .selectedmenu.mat-mdc-list-item:focus,
html body app-root div .mat-mdc-nav-list .selectedmenu.mat-mdc-list-item {
  background-color: rgb(42, 179, 222);

}

html body app-root div .selectedmenu .mdc-list-item__primary-text,
html body app-root div .mat-mdc-nav-list .selectedmenu.mat-mdc-list-item:focus .mdc-list-item__primary-text,
html body app-root div .mat-mdc-nav-list .selectedmenu.mat-mdc-list-item .mdc-list-item__primary-text {
  color: white;
}

/* html body app-root div .selectedmenu:before {
  position: absolute;
  left: 200px;
  content: ' ';
  width: 0;
  height: 0;
  border-top: 27px solid transparent;
  border-bottom: 27px solid transparent;
  border-left: 27px solid rgb(42,179,222);
}

html body app-root div .selectedmenu:before:hover {
  background: rgba(0, 0, 0, 0.04);
} */

.no-underline .mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline,
.no-underline .mat-mdc-select-underline {
  display: none !important;
}

body .mat-drawer-container {
  /* background-color: rgb(242,242,242); */
  background-color: rgb(255, 255, 255);
  /* color: rgba(0, 0, 0, 1); */
}

@media screen and (max-width: 699px) {

  .list-page,
  .detail-page,
  .asetukset-page {
    padding: 0px 15px 30px;
  }

  .list-page {
    padding-top: 10px;
  }

  .list-page .primarybutton {
    line-height: 36px;
  }

  .list-page .primarybutton .mat-icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
  }

  .lemon-dialog-header button {
    right: -10px;
  }

  html body .mat-mdc-dialog-content {
    padding: 0 10px;
    /* Check lemon-dialog-header button right value, minkä pitää olla sama kuin padding-right */
    margin: 0;
  }

  html body lemon-dialog-header h2 {
    padding-top: 40px;
  }

}

@media screen and (min-width: 700px) and (max-width: 1000px) {

  .list-page .primarybutton {
    font-size: 16px;
    line-height: 41px;
  }

  .list-page .primarybutton .mat-icon {
    width: 27px;
    font-size: 27px;
  }

}

/* input::-webkit-textfield-decoration-container {
  display: none;
} */

input[decimalNumberField] {
  text-align: right;
}

input[decimalNumberField].alignLeft {
  text-align: left;
}

input[autocomplete=off]::-webkit-credentials-auto-fill-button,
input[autocomplete=off]::-webkit-contacts-auto-fill-button,
input[autocomplete=kirjanpitoaineistoarkki]::-webkit-credentials-auto-fill-button,
input[autocomplete=kirjanpitoaineistoarkki]::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

.list-page .ylaosan-kontrollit {
  margin-bottom: 1em;
  display: flex;
  flex-flow: column;
}

.list-page .lisaa-nappula-container {
  align-self: flex-end;
}

.list-page .hakukontrollit {
  display: flex;
  flex-flow: column;
}

.list-page .hakukontrollit mat-form-field {
  margin: 0 auto;
  width: 80%;
}

@media screen and (min-width: 500px) {
  .list-page .hakukontrollit {
    flex-flow: row;
    justify-content: space-around;
    justify-content: space-evenly;
  }

  .list-page .hakukontrollit mat-form-field {
    margin: 0;
    width: 25vw;
    max-width: 200px;
  }
}

@media screen and (min-width: 450px) {
  .list-page.kapea .ylaosan-kontrollit {
    flex-flow: row;
    justify-content: flex-start;
  }

  .list-page.kapea .hakukontrollit {
    flex-flow: row;
    order: 1;
    margin: 0 auto 0 3em;
  }

  .list-page.kapea .hakukontrollit mat-form-field {
    margin: 0;
    width: 40vw;
    max-width: 300px;
  }

  .list-page.kapea .lisaa-nappula-container {
    order: 2;
    align-self: center;
  }
}

@media screen and (min-width: 801px) {

  .list-page,
  .detail-page,
  .asetukset-page {
    padding: 30px 75px;
  }

  .list-page.kapea .hakukontrollit {
    justify-content: flex-start;
    margin: 0 auto 0 0;
  }

  .list-page .hakukontrollit mat-form-field {
    margin: 0;
    width: 20vw;
    max-width: 200px;
  }
}

@media screen and (min-width: 1170px) {
  .list-page .hakukontrollit mat-form-field {
    margin-right: 3em;
  }

  .list-page .ylaosan-kontrollit {
    flex-flow: row;
  }

  .list-page .lisaa-nappula-container {
    order: 2;
    margin-left: auto;
    align-self: center;
  }

  .list-page .hakukontrollit {
    order: 1;
  }
}

pdf-viewer,
.ng2-pdf-viewer-container,
.pdfViewer {
  margin: 0 -20px !important;
}

html body div .pdfViewer .page {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  margin: 20px auto !important;
}

/** MAT-CARD */
.mat-mdc-card.mdc-card {
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  padding: 16px;
}





























body .kuitti-laatikko .backdrop {
  z-index: 999;
}

mat-checkbox label.mdc-label {
  font-weight: 500;
  white-space: nowrap;
}

mat-checkbox.pitka-label label.mdc-label,
mat-checkbox.keskitetty-label label.mdc-label {
  white-space: normal;
}

mat-checkbox.pitka-label label .mdc-checkbox {
  margin-top: 4px;
}

mat-checkbox.keskitetty-label label .mdc-checkbox {
  top: -5px;
}

body mat-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #61D263;
  --mdc-switch-selected-handle-color: white;
  --mdc-switch-selected-hover-state-layer-color: #61D263;
  --mdc-switch-selected-pressed-state-layer-color: #61D263;
  --mdc-switch-selected-focus-handle-color: white;
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-pressed-handle-color: white;
  --mdc-switch-selected-focus-track-color: #61D263;
  --mdc-switch-selected-hover-track-color: #61D263;
  --mdc-switch-selected-pressed-track-color: #61D263;
  --mdc-switch-selected-track-color: #61D263;
  --mdc-switch-selected-icon-color: white;
  --mdc-switch-selected-icon-opacity: 0;

  --mdc-switch-unselected-focus-handle-color: white;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: white;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: white;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-track-color: rgba(0, 0, 0, .38);
  --mdc-switch-unselected-icon-opacity: 0;

  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-disabled-selected-icon-opacity: 0
}

.maksut-katsele-barcode img {
  width: 100%;
  max-width: 500px;
}

.mat-mdc-header-cell {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 400;
}

.mat-mdc-cell,
.mat-mdc-footer-cell {
  font-size: 14px;
}

/** TABS */
html .mdc-tab {
  min-width: 160px;
}

.mat-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
}

html .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  /** Override the default strected tabs logic to avoid setting the input programmatically. 
  * Should be removed when MAT_TABS_CONFIG support removing the stretch globally. */
  flex-grow: 0;
}

html .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
html .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
html .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label {
  color: black;
}

html span[matsuffix] {
  position: relative;
  top: 23px;
}


.maksut .mat-mdc-tab .mdc-tab__text-label {
  font-size: 20px;
}



.uutta-sign {
  background-color: rgb(255, 72, 102);
  font-size: 12px;
  padding: 0 10px;
  border-radius: 20px;
  color: white;
  position: absolute;
  font-weight: 500;
  z-index: 1;
}

.isonappi-container .uutta-sign {
  transform: rotate(315deg);
  left: -10px;
  top: 5px;
  font-size: 12px;
  line-height: 21px;
}

.mat-mdc-list-item .uutta-sign {
  margin-left: 20px;
  margin-top: 3px;
  padding: 2px 10px;
}

.mat-mdc-tab .uutta-sign {
  transform: rotate(315deg);
  font-size: 9px;
  padding: 0 5px;
  line-height: 18px;
  left: -2px;
  top: 7px;
}

.tosite-api-msg {
  font-style: italic;
}

.kuitti-laatikko .tosite-api-msg {
  font-size: 12px;
}

.link-like {
  color: blue;
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

.link-like:hover {
  color: blue;
  color: -webkit-link;
}